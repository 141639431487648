import dayjs from 'dayjs';

import { ActivitiesProps, JobProps } from 'types/WorkLogTypes';

interface ActivityType {
  activity_id: string;
  activity_name: string;
  status: string;
  groupType: string;
}

export const CategoriseActivities = (
  activities: ActivitiesProps[],
  userActivities: ActivitiesProps[],
  jobs: JobProps[],
  jobId: string,
) => {
  const recommendedActivities: Array<ActivityType> = [];
  const fromJobActivities: Array<ActivityType> = [];
  const yourActivities: Array<ActivityType> = [];
  const jobActivities = jobs.find((job) => job.job_id === jobId)?.activities;

  jobActivities?.forEach((activity) => {
    const commonActivity = userActivities.find(
      (userActivity: ActivitiesProps) => userActivity.activity_id === activity.activity_id,
    );
    if (commonActivity) {
      recommendedActivities.push({
        activity_id: activity.activity_id,
        activity_name: activity.activity_name,
        status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
        groupType: 'Matched',
      });
    } else {
      fromJobActivities.push({
        activity_id: activity.activity_id,
        activity_name: activity.activity_name,
        status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
        groupType: 'From Job',
      });
    }
  });

  /* Users no longer have the ability to select their own activities that are not part of the job.
     This is to ensure that the activities are consistent with the job.
     Leaving this code here for reference.
  */

  // userActivities.forEach((activity: ActivitiesProps) => {
  //   if (!jobActivities?.some((jobActivity) => jobActivity.activity_id === activity.activity_id)) {
  //     yourActivities.push({
  //       activity_id: activity.activity_id,
  //       activity_name: activity.activity_name,
  //       status: activities.find((aty) => aty.activity_id === activity.activity_id)?.status || '',
  //       groupType: 'Your Activities',
  //     });
  //   }
  // });

  return [
    ...recommendedActivities,
    ...fromJobActivities,
    ...yourActivities,
    // { activity_name: 'Select Other Activity', activity_id: 'other', groupType: 'Other Activity', status: '' },
  ];
};

export const getHistoricalRate = (activities: ActivitiesProps[], activityId: string, createdDate: any) => {
  const activityHistoricalRates = activities.find((activity) => activity.activity_id === activityId)?.historical_rates;

  if (!activityHistoricalRates) return 0;

  const quotedDate = dayjs(createdDate);

  // Find the rate that was applicable on the quoted date
  const matchingRate = activityHistoricalRates?.find(({ applicable_from, applicable_till }) => {
    const isAfterStart = quotedDate.isAfter(dayjs(applicable_from));
    const isBeforeEnd = applicable_till ? quotedDate.isBefore(dayjs(applicable_till)) : false;
    return isAfterStart && isBeforeEnd;
  });

  if (matchingRate) return matchingRate.rate_excluding_tax;

  // If no matching rate is found, return current rate
  const ongoingRate = activityHistoricalRates.find(
    ({ applicable_till }) => applicable_till === null,
  )?.rate_excluding_tax;

  return ongoingRate || 0;
};
