import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eeaLogoShort } from 'images';
import { RootState } from 'store';

import { Typography } from '@mui/material';

import SnackBar from 'components/SnackBar';
import { worklogActions } from 'features/worklog/worklogSlice';
import { busmanApiSlice } from 'services/busmanApi';
import { useLazyGetUnreadNotificationsCountByIdQuery } from 'services/busmanApi/notificationsEndpoints';

import { BadgeContainer } from './styles';

const NotificationCountBadge = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({ type: '', msg: '' });
  const { user } = useSelector((state: RootState) => state.auth);
  const [fetchNotificationCount] = useLazyGetUnreadNotificationsCountByIdQuery();
  const { unreadNotificationCount } = useSelector((state: RootState) => state.worklogs);
  const countRef = useRef<any>(0);
  countRef.current = unreadNotificationCount;

  const fetchUnreadNotificationsCount = async () => {
    const { data } = await fetchNotificationCount(user?.user_id);
    if (!data) return;

    // Update the notification count badge if the count has changed
    if (data.unread_count !== countRef.current) dispatch(worklogActions.setNotifications(data.unread_count));
    // Show a notification if there are new notifications
    if (data.unread_count > countRef.current) {
      dispatch(busmanApiSlice.util.invalidateTags(['Notification']));

      setMessage({
        type: 'success',
        msg: 'You have new notifications!',
      });

      new Notification('Busmin Notification', {
        icon: eeaLogoShort,
        body: 'You have new notifications!',
        badge: eeaLogoShort,
      });
      return;
    }
  };

  // Notification badge refetch every 30 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      await fetchUnreadNotificationsCount();
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchNotificationCount, user?.user_id]);

  // Initial fetch of unread notifications count
  useEffect(() => {
    fetchUnreadNotificationsCount();
  }, []);

  //Listener for new push notifications from firebase
  // const enableMessageListener = async () => {
  //   if (supported) {
  //     onMessageListener()
  //       .then((payload) => {
  //         if (payload?.data.userId === user?.user_id) {
  //           setMessage({ type: 'success', msg: `Notification: ${payload.data.title}` });
  //           new Notification(payload.data.title, {
  //             icon: eeaLogoShort,
  //             body: payload.data.body,
  //             badge: eeaLogoShort,
  //           });
  //         }
  //         refetch();
  //       })
  //       .catch((err) => console.error(new Error(err)));
  //   } else {
  //     console.log('Firebase Push notifications are not supported in this browser');
  //   }
  // };

  // useEffect(() => {
  //   enableMessageListener();
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleCloseSnackbar = () => {
    setMessage({ type: '', msg: '' });
  };

  return (
    <>
      <SnackBar
        message={message}
        onClose={handleCloseSnackbar}
        snackbarOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      />
      <BadgeContainer sx={{ display: unreadNotificationCount === 0 ? 'none' : 'flex' }}>
        <Typography
          sx={{
            color: 'white',
            fontSize: '8px',
          }}>
          {unreadNotificationCount}
        </Typography>
      </BadgeContainer>
    </>
  );
};

export default NotificationCountBadge;
