import { worklogActions } from 'features/worklog/worklogSlice';

import { busmanApiSlice } from './busmanApiSlice';
import { FcmTokenType, GetNotificationsCountResponse, GetNotificationsResponse } from './types';

export const notificationsEndpoints = busmanApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotificationsById: builder.query({
      query: (userId) => `/notifications/?user_id=${userId}`,
      transformResponse: (response: GetNotificationsResponse) => response.data,
      providesTags: ['Notification'],
    }),

    getUnreadNotificationsCountById: builder.query({
      query: (userId) => `/notifications/unread?user_id=${userId}`,
      transformResponse: (response: GetNotificationsCountResponse) => response.data,
    }),

    addUserFCMToken: builder.mutation({
      query: (newToken: FcmTokenType) => ({
        url: '/fcm/',
        method: 'post',
        body: newToken,
      }),
    }),

    updateUserNotification: builder.mutation({
      query: (notification) => {
        return {
          url: '/notifications/',
          method: 'post',
          body: notification,
        };
      },
      invalidatesTags: ['Notification'],
    }),

    updateNotificationSeen: builder.mutation({
      query: (notificationId) => {
        return {
          url: '/notifications/seen',
          method: 'put',
          body: notificationId,
        };
      },
      invalidatesTags: ['Notification'],
    }),
  }),
});

export const {
  useGetNotificationsByIdQuery,
  useLazyGetUnreadNotificationsCountByIdQuery,
  useAddUserFCMTokenMutation,
  useUpdateNotificationSeenMutation,
  useUpdateUserNotificationMutation,
} = notificationsEndpoints;
