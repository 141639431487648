import * as React from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { RootState } from 'store';

import { ArrowForwardIos, Search } from '@mui/icons-material';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import {
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import {
  FilterButtons,
  FilterPanelContainer,
  ItemListContainer,
  NoDataBox,
  PageContainer,
} from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import SnackBar, { MessageType } from 'components/SnackBar';
import { worklogActions } from 'features/worklog/worklogSlice';
import {
  useGetNotificationsByIdQuery,
  useUpdateNotificationSeenMutation,
} from 'services/busmanApi/notificationsEndpoints';
import { NotificationType } from 'services/busmanApi/types';

import { NotificationBanner } from './styles';

const NotificationsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewList = ['All', 'Read', 'Unread'];
  const [inputText, setInputText] = useState('');
  const [view, setView] = useState(viewList[0]);
  const [markedForRead, setMarkedForRead] = useState<string[]>([]);
  const [message, setMessage] = useState<MessageType | null>(null);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { filterPanel } = useSelector((state: RootState) => state.filterPanel);
  const { user } = useSelector((state: RootState) => state.auth);
  const { unreadNotificationCount } = useSelector((state: RootState) => state.worklogs);

  const { data: notificationsData } = useGetNotificationsByIdQuery(user.user_id);
  const [markNotificationRead] = useUpdateNotificationSeenMutation();

  const unSeenNotificationIds = notificationsData
    ?.filter((notification) => !notification.seen)
    .map((notifications) => notifications.id);

  const filteredData =
    notificationsData &&
    notificationsData
      .filter((data: NotificationType) => {
        if (inputText === '') {
          return true;
        } else {
          return data.title.toLowerCase().includes(inputText.toLowerCase());
        }
      })
      .filter((notification: NotificationType) => {
        if (view === 'Read') {
          return notification.seen;
        }
        if (view === 'Unread') {
          return !notification.seen;
        }
        return notification;
      })
      .sort((a, b) => {
        if (a.seen === b.seen) {
          const dateA = +new Date(a.created_at);
          const dateB = +new Date(b.created_at);
          return dateB - dateA;
        }
        return a.seen ? 1 : -1;
      });

  const markAsRead = async (ids: string[] | undefined) => {
    const params = {
      user_id: user.user_id,
      notification_id: ids,
    };

    const result = await markNotificationRead(params);

    if ('error' in result) {
      setMessage({ type: 'error', msg: 'Error marking as read:' });
    }
    if ('data' in result) {
      setMarkedForRead([]);
      setMessage({ type: 'success', msg: result.data.message });
      dispatch(worklogActions.setNotifications(unreadNotificationCount - Number(ids?.length)));
    }
  };

  const notificationOnClick = (notification: NotificationType) => {
    if (notification.type === 'W') navigate(`/worklogs/${notification.related_type_id}`);
    if (notification.type === 'J') navigate(`/jobs/${notification.related_type_id}`);
    if (notification.seen) return;
    markAsRead([notification.id]);
  };

  const getIcon = (type: string) => {
    switch (type) {
      case 'W':
        return <ForumOutlinedIcon sx={{ color: theme.palette.text.disabled, fontSize: '16px' }} />;
      case 'J':
        return <ForumOutlinedIcon sx={{ color: theme.palette.text.disabled, fontSize: '16px' }} />;
      default:
        return <ForumOutlinedIcon sx={{ color: theme.palette.text.disabled, fontSize: '16px' }} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Busmin</title>
        <meta name="Busmin" content="Notifications" />
      </Helmet>
      <PageContainer>
        {filterPanel && (
          <FilterPanelContainer>
            {!isMobileScreen && (
              <Typography gutterBottom sx={{ mb: theme.spacing(4) }} variant="h4">
                Notifications
              </Typography>
            )}
            <OutlinedInput
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Search Notifications"
              sx={{
                height: '32px',
                mb: theme.spacing(3),
              }}
              value={inputText}
            />
            <Typography sx={{ color: theme.palette.text.disabled, mb: theme.spacing(0.5) }} variant="body2">
              VIEW
            </Typography>
            <Select
              onChange={(newSelection) => {
                setView(newSelection.target.value);
              }}
              sx={{ height: '32px' }}
              value={view}>
              {viewList.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                );
              })}
            </Select>
            <FilterButtons sx={{ marginLeft: 'auto' }}>
              {!markedForRead.length ? (
                <CustomButton
                  disabled={!unSeenNotificationIds?.length}
                  onClick={() => markAsRead(unSeenNotificationIds)}
                  variant="contained"
                  text="Mark all as read"
                  width="medium"
                />
              ) : (
                <CustomButton
                  disabled={!unSeenNotificationIds?.length}
                  onClick={() => markAsRead(markedForRead)}
                  variant="contained"
                  text="Mark as read"
                  width="medium"
                />
              )}
            </FilterButtons>
          </FilterPanelContainer>
        )}
        {notificationsData && notificationsData.length === 0 ? (
          <NoDataBox>
            <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
              You have no notifications...
            </Typography>
          </NoDataBox>
        ) : (
          <ItemListContainer>
            {filteredData && filteredData.length === 0 && notificationsData.length !== 0 && (
              <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
                No results found...
              </Typography>
            )}
            {filteredData &&
              filteredData?.map((notification: NotificationType, idx: number) => {
                const showDivider = idx > 0 && notification?.seen !== filteredData[idx - 1].seen;
                return (
                  <>
                    {showDivider && <Divider>Read</Divider>}
                    <NotificationBanner
                      onClick={() => {
                        notificationOnClick(notification);
                      }}
                      container
                      key={idx}
                      sx={{
                        backgroundColor: !notification.seen
                          ? theme.palette.background.default
                          : theme.palette.background.paper,
                      }}>
                      {!isMobileScreen && (
                        <GridItem
                          customStyles={{ paddingInlineStart: 0 }}
                          title={
                            <Checkbox
                              checked={markedForRead.includes(notification.id)}
                              icon={
                                <SquareRoundedIcon
                                  sx={{
                                    backgroundColor: theme.palette.background.default,
                                    color: notification.seen
                                      ? theme.palette.busmanColors.lightGrey
                                      : theme.palette.background.default,
                                  }}
                                />
                              }
                              checkedIcon={
                                <SquareRoundedIcon
                                  sx={{
                                    backgroundColor: theme.palette.background.default,
                                    color: theme.palette.busmanColors.busmanPrimary,
                                  }}
                                />
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (notification.seen) return;
                                if (!markedForRead.includes(notification.id))
                                  setMarkedForRead((prev) => [...prev, notification.id]);
                                else setMarkedForRead((prev) => prev.filter((id) => id !== notification.id));
                              }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                                '& .MuiTypography-root': {
                                  paddingLeft: 0,
                                },
                                '& .MuiSvgIcon-root': {
                                  opacity: notification.seen ? 0.5 : 1,
                                  width: '13px',
                                  height: '13px',
                                  backgroundColor: notification.seen ? theme.palette.background.default : 'transparent',
                                  border: `1px solid ${theme.palette.busmanColors.lightGrey}`,
                                  borderRadius: '3px',
                                },
                              }}
                            />
                          }
                          breakpoint={1}
                          seenOrIsHeading={false}
                        />
                      )}

                      <GridItem
                        title={
                          dayjs(notification.created_at).isSame(dayjs(), 'day')
                            ? `Today at ${dayjs(notification.created_at).format('HH:mm a')}`
                            : dayjs(notification.created_at).format('DD/MM/YYYY')
                        }
                        seenOrIsHeading={notification.seen}
                        customStyles={{ paddingInlineStart: isMobileScreen ? theme.spacing(1) : 0 }}
                        breakpoint={isTabletScreen ? 2.4 : 1}
                      />
                      {!isMobileScreen && (
                        <Grid
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          item
                          justifyContent="right"
                          md={0.6}
                          sm={0.6}
                          xs={0.6}>
                          {getIcon(notification.type)}
                        </Grid>
                      )}
                      <GridItem
                        title={`${notification.title}: ${notification.message}`}
                        seenOrIsHeading={notification.seen}
                        breakpoint={7}
                      />
                      <Grid
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        item
                        justifyContent="end"
                        marginLeft="auto"
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ paddingInlineEnd: theme.spacing(2) }}>
                        <ArrowForwardIos sx={{ color: theme.palette.text.disabled, fontSize: '0.8rem' }} />
                      </Grid>
                    </NotificationBanner>
                  </>
                );
              })}
          </ItemListContainer>
        )}
      </PageContainer>
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </>
  );
};

export default NotificationsPage;
